@import './oppsummert';

#oppsummert {
  margin: 0;
  padding-bottom: 0;

  .header {
    margin: 0 var(--space-4);
  }

  .feed {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-padding: 0 0 0 var(--space-4);
    -webkit-overflow-scrolling: touch;
    padding: 0 var(--space-4);
    border-bottom: none;
  }

  .feed-item {
    border-right: none;
    padding-right: 0;
    margin: 0 var(--space-4) var(--space-4) 0;
  }

  .feed-item-desc-wrapper {
    position: unset;
    padding: 0;
  }

  .oppsummert-bottom-wrapper {
    height: unset;
  }

  .feed-item-desc,
  .summary {
    margin: 0;
    z-index: 1;
  }
}
