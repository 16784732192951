$local_coverContainerSpread: 500px;
$local_headerWidth: 180px;
$local_headerOffset: $local_headerWidth + 2 * $gridBase;
$local_minWidthForHeaderOffset: 980px + 2 * $local_headerOffset;
$local_minWidthFor4Columns: 1640px;
$local_rowHeight: 88px;

a.widget-link {
  color: transparent;
}

.widget-oppsummert,
.widget-followed-content {
  margin-bottom: $gridBase;

  .widget-list {
    margin: 0;
  }
}

.have-you-read-widget,
.widget-oppsummert,
.widget-followed-content {
  padding: $gapStandard 0;
  background-color: $colorContentBackground;

  .floating-header,
  .widget-header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    .current-time,
    .expand-button {
      display: none;
    }

    .title {
      font-size: 20px;
      font-family: $fontArialBold;
      line-height: 1.2;
      font-weight: bold;
      border-left: solid 1px $colorMediumGrey;
      margin-left: 45px;
      padding-left: $gridBase;

      &::before {
        @include a-logo(20px, 20px, 14px, 1px);
      }
    }
    .description {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-family: $fontRobotoLight;
      font-weight: normal;
      font-size: 13px;
      line-height: 1.4;
      color: $colorApDark;
      padding: 4px $gapStandard 0 $gridBase;
    }
  }

  .games,
  .podcasts {
    box-sizing: border-box;
    width: 100%;
    border-left: 7px solid $colorApDark;
    padding-left: $gapStandard;

    .article-link:nth-child(n + 4) {
      display: none;
    }

    .game,
    .podcast {
      display: flex;
      flex-direction: row;
      margin-top: $gapStandard;

      .article-link:first-child & {
        margin-top: 0;
      }

      .image-container,
      .icon-container {
        img {
          width: 80px;
          height: 80px;
          object-fit: cover;
          margin-right: $gridBase;
        }
      }

      .title-container {
        flex: 1;
      }

      .paywall-state {
        display: none;
      }

      .title,
      .description {
        color: $colorApDark;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: $gapStandard;
      }
    }
  }
  a.widget-more-link {
    text-decoration: none;
    display: block;
    font-size: 15px;
    font-family: Roboto-Light, Arial, Helvetica, sans-serif;
    color: #000c26;
    position: absolute;
    right: 16px;

    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 3px;
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 2px;
      margin-left: 8px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
}
