@include graphik('-Semibold', 600);
@include graphik('-Medium', 500);

.morning-brief {
  background-color: var(--c-blue-10);
  padding: var(--space-6) var(--space-4) var(--space-4);
  color: var(--c-dark);
  margin-bottom: var(--space-2);
  font-family: 'Publico', Georgia, Times, 'Times New Roman', serif;
  font-weight: 400;
  line-height: 25px;

  button,
  a {
    &:focus-visible {
      outline: var(--c-text-link-hover) 2px solid;
      outline-offset: 1px;
    }
  }

  &__article-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__close-button {
    align-self: end;
    border: none;
    background-color: transparent;

    &::after {
      content: '';
      display: inline-block;
      background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/close.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 12px;
      height: 12px;
      filter: brightness(0) saturate(100%) invert(5%) sepia(31%) saturate(5275%)
        hue-rotate(205deg) brightness(100%) contrast(106%);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: var(--space-4);
    margin-bottom: var(--space-5);

    &-image {
      height: 60px;
      width: 60px;
      margin: 0;

      img {
        height: 60px;
        width: 60px;
        object-fit: cover;
      }
    }
  }

  &__title {
    @include title(26px, 30px, normal, 400);

    i {
      color: #00259d;
    }
  }

  &__content {
    position: relative;

    &--collapsed {
      height: 65px;
      overflow: hidden;

      &::after {
        height: 55px;
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
        background-image: linear-gradient(
          179deg,
          rgba(238, 243, 249, 0) 0.81%,
          #eef3f9 71.23%
        );
      }
    }

    &--expanded {
      height: auto;
    }
  }

  &__paragraphs-wrapper {
    padding-bottom: var(--space-6);
    margin-bottom: var(--space-6);
    border-bottom: 2px solid #e5eaf2;
  }

  &__paragraph-item {
    font-size: 17px;
    line-height: 25px;
    margin: 0 0 var(--space-6) 0;

    &:last-child {
      margin: 0;
    }

    strong {
      font-weight: 600;
    }

    a.link {
      color: var(--c-text-link);
      text-decoration: underline;

      &:visited {
        color: var(--c-hyperlink-pressed);
      }
    }
  }

  &__toggle-button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    @include label-1(var(--c-light), 500);
    font-size: 14px;
    background-color: var(--c-text-link);
    padding: var(--space-3) var(--space-6);
    border-radius: 100px;
    z-index: 1;

    &::after {
      content: '';
      display: inline-block;
      background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/chevron-down-white.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 14px;
      height: 12px;
      margin-left: var(--space-2);
    }

    &--expanded {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &__byline {
    margin-bottom: var(--space-6);

    &-callout {
      margin: 0 0 var(--space-2) 0;
      @include title(22px, 26px, italic, 400);
      color: #00259d;
    }

    &-author-wrapper {
      gap: 12px;
      display: flex;
      align-items: center;
    }

    &-author-image {
      height: 56px;
      width: 56px;
      margin: 0;

      img {
        height: 56px;
        width: 56px;
        object-fit: cover;
      }
    }

    &-name {
      @include title(14px, 18px, normal, 500);
      letter-spacing: 0;
      font-family: Graphik;
      margin: 0;
    }

    &-title {
      @include title(14px, 18px, italic, 400);
      letter-spacing: 0;
      color: var(--c-text-informational);
      margin: 0;
    }
  }

  &__feedback {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    background-color: #fff;
    padding: var(--space-4);
    margin-bottom: var(--space-6);
    font-family: Graphik;

    &-label {
      margin: 0;
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      text-align: justify;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      gap: var(--space-4);
    }

    &-length-wrapper,
    &-comment-wrapper {
      display: flex;
      flex-direction: column;
      gap: var(--space-4);
      width: 100%;
    }

    &-buttons-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: var(--space-2);
    }

    &-button {
      flex: 1 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--c-text-link);
      border: 1px solid var(--c-text-link);
      border-radius: 100px;
      padding: var(--space-2) var(--space-4);
      gap: var(--space-2);
      background-color: #fff;

      font-family: Graphik;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      white-space: nowrap;

      &--active {
        background-color: #e8f1fa;
        border-color: var(--c-text-link);
      }
    }

    &-confirmation-text {
      font-family: Graphik;
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }

    &-survey-invitation {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      gap: var(--space-2);

      &-label {
        margin: 0;
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        text-align: left;
      }

      &-text {
        margin: 0 0 var(--space-2) 0;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
      }

      &-link {
        display: inline-block;
        padding: var(--space-1) var(--space-5);
        border: 1px solid var(--c-text-link);
        border-radius: 100px;
        font-size: 12px;
        font-weight: 600;
        background-color: #fff;
        color: var(--c-text-link);
        align-self: start;
      }
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    margin: var(--space-4) 0;
    background-color: var(--c-elements-background-grey);
  }
}
