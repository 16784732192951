.jwplayer.jw-skin-morning-brief-tts {
  .jw-button-container {
    .jw-icon-playback {
      background-color: transparent !important;
      border: 1px solid #1838a2 !important;

      svg {
        filter: brightness(0) saturate(100%) invert(9%) sepia(39%)
          saturate(5088%) hue-rotate(201deg) brightness(142%) contrast(143%);
      }
    }
  }
}
