.teaser {
  .information {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
    margin-bottom: var(--space-2);
    border-bottom: none;
    position: relative;
  }
  .information .context-label {
    @include label-2(var(--c-dark), 600);
    display: flex;
    &--icon {
      height: 16px;
    }

    .relevant-tag {
      border-left: var(--divider-line);
      display: inline-block;
      padding-left: var(--space-2);
      margin-left: 6px;
      font-weight: 400;
      color: var(--c-neutral-07);
      white-space: pre;
    }
  }
  .comments-count {
    @include label-2(var(--c-neutral-07), 400);
    position: absolute;
    right: 0;
    top: 0px;
    display: flex;

    .bubble {
      height: 22px;
      text-align: center;
      padding: 0 var(--space-1);
      margin-left: var(--space-2);
      background-color: var(--c-shade-10);
      border-radius: 2px;
      position: relative;
      min-width: calc(23px - var(--space-1) * 2);
      max-height: 18px;

      &::after {
        content: '';
        position: absolute;
        right: 50%;
        bottom: -4px;
        width: 7px;
        height: 4px;
        background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/comments/regular-light-tail.svg');
        background-repeat: no-repeat;
      }

      span {
        position: relative;
        bottom: -1px;
      }
    }
  }

  &.small:not(.top-small-vertical),
  &.large:not(:has(.teaser-image-wrapper)):not(.feature),
  &.medium:not(:has(.teaser-image-wrapper)):not(.feature) {
    &.series-reveal,
    &.series-live,
    &.series-document {
      .context-label {
        font-size: 0;
        background-color: transparent;
        padding: 0;
        color: transparent;
      }
      .relevant-tag {
        display: none;
      }
      .information {
        padding-left: 0;
      }
    }
  }
}

@include teaser-series-top('.small.top-small-horizontal', '.information');
@include teaser-series-top('.small.top-small-noimage', '.information');
@include teaser-series-top(
  '.small:not(.top-small-vertical):not(.video-link)',
  '.information'
);
@include teaser-series-top(
  '.large:not(.video-link):not(.feature):not(:has(.teaser-image-wrapper))',
  '.information'
);
@include teaser-series-top(
  '.medium:not(.video-link):not(.series-location):not(.feature):not(:has(.teaser-image-wrapper))',
  '.information'
);
@include teaser-series-top(
  '.fullwidth:not(.mobile) > .medium:not(.video-link):not(.feature)',
  '.information'
);

.teaser.breaking {
  .information .context-label {
    position: relative;
    background-color: #b0002f;
    padding: 3px 8px;
    font-weight: 600;
    color: white;
    font-size: 14px;
    font-family: Graphik;
    border-radius: 2px;
  }
}

.teaser.video-link {
  .information .context-label-video-link {
    @include label-2(var(--c-light));
    display: flex;
    align-items: center;
    background-color: var(--c-dark);
    padding: 0 8px;
    border-radius: 2px;
    height: 18px;
    gap: 4px;

    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 12px;
      background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/audio/play-rounded.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.teaser.breaking.inverted {
  .information .context-label {
    &::before,
    &::after {
      background: var(--c-breaking-dark);
    }
  }
}

.teaser {
  &.inverted {
    .information {
      border-color: var(--c-tint-30);
      .context-label {
        color: var(--c-light);
      }
      .relevant-tag {
        color: var(--c-neutral-04);
        border-color: var(--c-tint-30);
      }
    }
    .comments-count {
      .bubble {
        color: var(--c-neutral-03);
        background-color: var(--c-tint-30);

        &::after {
          background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/comments/regular-dark-tail.svg');
        }
      }
    }
  }
}

.teaser.feature {
  .information {
    justify-content: center;
    padding-bottom: 0;
  }

  .information .context-label {
    color: var(--c-light);

    .relevant-tag {
      color: var(--c-neutral-05);
      border-color: var(--c-neutral-10);
    }
  }
  .comments-count {
    top: var(--space-4);
    right: var(--space-4);
  }
}

.teaser.memorial {
  .information {
    margin: var(--space-4);
    padding: 0;
    justify-content: center;
  }
}

.teaser.magazine {
  .information {
    justify-content: center;
  }
}

//desktop

@mixin labelGradient(
  $clmw,
  $cll,
  $com_clmw,
  $com_cll,
  $tts_clmw,
  $tts_l,
  $com_tts_l: 135px
) {
  .information {
    .context-label {
      white-space: nowrap;
      max-width: $clmw;
      overflow-x: clip;
    }
    &::after {
      content: '';
      position: absolute;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        #fff 90%
      );
      left: $cll;
      height: 24px;
      width: 24px;
    }
    &.comments {
      .context-label {
        max-width: $com_clmw;
      }
      &::after {
        left: $com_cll;
      }
    }
  }
}

@media (min-width: 765px) {
  .fullwidth {
    @include teaser-series-top(
      '.medium:not(.video-link):not(.series-location):not(.feature)',
      '.information'
    );
    .medium {
      &.series-reveal,
      &.series-live,
      &.series-document {
        .context-label {
          font-size: 0;
          background-color: transparent;
          padding: 0;
          color: transparent;
        }
        .relevant-tag {
          display: none;
        }
        .information {
          padding-left: 0;
        }
      }
    }
  }

  .teaser.medium:not(.inverted) {
    @include labelGradient(250px, 232px, 226px, 205px, 226px, 205px, 200px);
  }

  .block.x-large .teaser.medium {
    @include labelGradient(250px, 232px, 226px, 140px, 226px, 140px, 200px);
  }

  .block.feature .teaser.medium:not(.inverted, .feature) {
    @include labelGradient(250px, 232px, 226px, 160px, 226px, 160px, 135px);
  }

  .block:not(.full-width-top, .full-width)
    .teaser.small.horizontal:not(
      .inverted,
      .series-document,
      .series-reveal,
      .series-live,
      .series-location
    ) {
    @include labelGradient(238px, 226px, 224px, 200px, 220px, 198px);
  }

  .block:not(.full-width-top, .full-width)
    .teaser.small.horizontal.breaking:not(
      .inverted,
      .series-document,
      .series-reveal,
      .series-live,
      .series-location
    ) {
    @include labelGradient(238px, 226px, 224px, 200px, 220px, 198px);

    .information {
      .context-label {
        overflow-x: visible;
      }
    }
  }

  .teaser.medium.breaking .information .context-label {
    overflow-x: visible;
  }

  .teaser {
    .comments-count {
      .bubble {
        min-width: 23px;
      }
    }
  }

  .teaser.medium.kort-forklart {
    .information {
      .context-label {
        max-width: 465px;
      }
      &::after {
        left: 441px;
      }
    }
  }

  .teaser.medium.feature .information {
    .context-label {
      max-width: none;
    }
    &::after {
      content: none;
    }
  }

  .teaser.multimedia .information,
  .teaser.horizontal .information {
    padding-bottom: 0;
  }

  .teaser.small.illustration .information {
    &.comments {
      min-width: 240px;
    }
  }
}

.block.x-large .teaser.large .information .context-label {
  @include label-1(var(--c-dark), 600);
}
