body {
  font-family: $fontPublicoHeadlineBold;
  background: $colorMobileMainBackground;
  color: #000c26;
  margin: 0;
  &.ab-sub-colors {
    background: white;
  }
}

.ch-header-sub {
  display: none;
}

#inner-container {
  overflow-x: hidden !important;
}
