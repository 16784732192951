// Global variables for search results page (for all publications)
// Please do not change default values
// Any changes for specific publications should be done in publication config
// Publication config can be found here: '{{publication}}/public/scss/_search-config.scss

$search-color: inherit !default;

$fst-Search-page-max-width: 980px !default;
$fst-Search-searchbox-container-height: 50px !default;
$fst-Search-searchbox-container-width: 650px !default;
$fst-Search-searchbox-container-width-noresults: 880px !default;
$fst-Search-results-container-width: 600px !default;
$fst-Search-results-container-padding: 25px !default;
$fst-Search-filters-width: 300px !default;
$fst-Search-article-min-height: 150px !default;
$fst-Search-article-min-height-mobile: 120px !default;
$fst-Search-main-font-family: Arial, Helvetica, sans-serif !default;
$fst-Search-title-font-family: Georgia, 'Times New Roman', Times, serif !default;
$fst-Search-main-color: #194775 !default;
$fst-Search-input-placeholder-color: #b2b2b2 !default;
$fst-Search-gray-color-active: #777777 !default;
$fst-Search-gray-color-inactive: #999999 !default;
$fst-Search-mobile-breakpoint: 980px !default;
$fst-Search-abonnent-text: 'For abonnenter' !default;
$fst-Search-abonnent-color: #777777 !default;

@keyframes search-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
